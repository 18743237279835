(function init_changelog() {
  if (document.getElementById("jump-link")) {
    document.getElementById("jump-link").onclick = function () {
      if (document.getElementById("toggle-link")) {
        document.getElementById("toggle-link").click();
        document.getElementById("toggle-link").scrollIntoView(true);
        return false;
      }
    };
  }

  if (document.getElementById("toggle-link")) {
    document.getElementById("toggle-link").onclick = function () {
      var div = document.getElementById("collapsible-div");
      var link = document.getElementById("toggle-link");
      if (div.style.display === "none") {
        div.style.display = "block";
        link.textContent = "- hide all updates";
      } else {
        div.style.display = "none";
        link.textContent = "+ see all updates";
      }
      return false;
    };
  }
})()
